<template>
    <section class="image-card-container">
        <div class="image-card">
            <WatsonAtomImage
                v-if="card.image"
                class="image-card__image"
                :content="card.image"
                dimension="none"
            />

            <div class="image-card__content">
                <div class="image-card__content-main">
                    <WatsonAtomDynamicText
                        v-if="card.title"
                        class="image-card__content-title"
                        :text="card.title"
                        :typography="typography.cardTitle"
                    />

                    <p
                        v-if="card.description || card.alternativeDescription"
                        class="image-card__content-description u-paragraph u-regular"
                    >
                        {{ stripHtml(card.description || card.alternativeDescription) }}
                    </p>
                </div>

                <Link
                    v-if="card.url && card.buttonLabel"
                    class="image-card__content-button"
                    :href="card.url"
                >
                    <span
                        :class="typographyUtilityClasses(props.typography.buttonLabel)"
                        class="u-bold"
                    >
                        {{ card.buttonLabel }}
                    </span>
                </Link>
            </div>
        </div>
    </section>
</template>

<script setup>
import WatsonAtomDynamicText from '@atoms/dynamic-text/watson-atom-dynamic-text.vue';
import WatsonAtomImage from '@atoms/image/watson-atom-image.vue';

import stripHtml from '@utils/strip-html';
import { typographyUtilityClasses } from '@utils/typography';

const props = defineProps({
    content: {
        type: Object,
        required: false,
        default: () => ({}),
    },
    card: {
        type: Object,
        required: false,
        default: () => ({}),
    },
    typography: {
        type: Object,
        required: false,
        default: () => ({}),
    },
});
</script>

<style lang="scss">
@use 'watson-theme-image-card';
</style>

<style lang="scss" scoped>
@layer theme {
    .image-card-container {
        --background: v-bind('props.card?.style?.background || null');
        --title-color: v-bind('props.card?.style?.titleColor || null');
        --text-color: v-bind('props.card?.style?.textColor || null');
        --button-color: v-bind('props.card?.style?.buttonColor || null');
        --button-hover-color: v-bind('props.card?.style?.buttonHoverColor || null');
        --button-active-color: v-bind('props.card?.style?.buttonActiveColor || null');
    }
}
</style>
